<template>
    <div class="rn-product-area rn-section-gapTop">
        <div class="container" v-if="comparisonTable.length>0">
            <div class="row">
                <div class="col-12 mb--50">
                    <h3 class="title live-bidding-title">TJM France VS TJM Madagascar</h3>
                </div>
            </div>
            <div class="box-table table-responsive">
                <table class="table upcoming-projects">
                    <thead>
                    <tr>
                        <th class="text-capitalize"><span>{{ this.$t('home_page_onexus.table.profile') }}</span></th>
                        <th class="text-capitalize"><span>{{ this.$t('home_page_onexus.table.french_average') }}</span></th>
                        <th class="text-capitalize"><span>{{ this.$t('home_page_onexus.table.madagascar_average') }}</span></th>
                        <th class="text-capitalize"><span>{{ this.$t('home_page_onexus.table.seniority') }}</span></th>
                        <th class="text-capitalize"><span>{{ this.$t('home_page_onexus.table.difference') }} %</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :class="{'color-light': rowIndex%2 === 0}"
                        v-for="(row, rowIndex) in comparisonTable"
                        :key="`row-${rowIndex}`">
                        <!-- <td><span>{{ row.fields.poste }}</span></td> -->
                        <td><span>{{ translateProfile(row.fields.poste) }}</span></td>
                        <td class="text-center"><span class="color-danger">{{ row.fields.France }} €/{{ this.$t('home_page_onexus.table.day') }}</span></td>
                        <td class="text-center"><span class="color-green">{{ row.fields.Madagascar }} €/{{ this.$t('home_page_onexus.table.day') }}</span></td>
                        <td>
                            <span >
                                {{ row.fields["Séniorité"].startsWith('Expérimenté')? 
                                this.$t('home_page_onexus.table.experiment', {min: getMinMaxValues(row.fields["Séniorité"])[0] , max: getMinMaxValues(row.fields["Séniorité"])[1]})
                                :this.$t('home_page_onexus.table.senior', {min: getMinMaxValues(row.fields["Séniorité"])[0] , max: getMinMaxValues(row.fields["Séniorité"])[1]}) }}
                            </span>
                        </td>
                        <td class="text-center"><span class="color-green">{{ row.fields["Différence"] }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
export default {
    name: 'ComparisonOnexus',
    mixins:[SalScrollAnimationMixin],
    data(){
        return {
            comparisonTable: [],
            profiles: [],
        }
    },
    methods: {
        getComparison() {
            axios.get(`https://api.airtable.com/v0/appRVLOFnXG9wPeFh/tblPpMNvjh8ipOwnB`,{
                headers:{
                    'Authorization': `Bearer patUgCaODP2MMQWfa.003a848cede4738796e0a0443f6aeea7bd6beb18a841fb7b2447cf222e7d4073`
                   } 
            })
            .then(resGetComparison => {
                this.comparisonTable = resGetComparison.data.records
            })
            .catch(errGetComparison => {
                console.error({errGetComparison})
            })
        },
        getMinMaxValues(text_to_extract){
            const years = text_to_extract.match(/\d+/g)
            return [parseInt(years[0]), parseInt(years[1])]
        },
        extractProfiles(){
            this.comparisonTable.forEach(comparison => {
                this.profiles.push(comparison.fields.poste)
            })
        },
        translateProfile(text_to_translate) {
            const profile_data_variables = [
                "data_analyst",
                "database_administrator",
                "devops",
                "data_scientist",
                "cloud_engineer_and_architect",
                "chief_project",
                "mobile_developer",
                "full_stack_developer"
            ]
            const index_text_in_profile = this.comparisonTable.indexOf(this.comparisonTable.find(profile => profile.fields.poste===text_to_translate))
            if (index_text_in_profile !== undefined) {
                return this.$t(`home_page_onexus.table.profile_data.${profile_data_variables[index_text_in_profile]}`)
            } else {
                return text_to_translate
            }
        }

    },
    mounted(){
        this.getComparison()
    }
}
</script>
<style scoped>
.active-dark-mode .color-green{
    color:#53ff53 !important
}
.active-light-mode .color-green{
    color:#07aa07 !important
}

</style>